export enum Status {
    CALM = 'calm',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface CreateUrlPaymentFormData {
    price: number;
    alias: string;
    telegram_id: string | number;
    verbose_name: string;
    description: string;
    email: string;
}

export interface PaymentsSliceState {
    status: Status | string;
    message: string;
    payment_url: string | null;
    statusPayment: boolean | null;
}

export interface CheckPaymentFormData {
    telegram_id: string | number;
}

import style from './ServicesPay.module.scss';

// React
import { useEffect, useRef, useState } from 'react';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Ant Design
import { message as antdMessage } from 'antd';

// Telegram
import { BackButton, MainButton, useWebApp, WebAppUser } from '@vkruglikov/react-telegram-web-app';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../types/types';
import { useAppDispatch } from '../../redux/store';
import { setPackageByAlias } from '../../redux/packages/slice';
import { selectPackages } from '../../redux/packages/selectors';
import { PromoCodeFormData } from '../../redux/promocode/types';
import { selectPromoCode } from '../../redux/promocode/selectors';
import { requestActivatePromoCode } from '../../redux/promocode/asyncActions';

// Components
import Spinner from '../../components/Spinner/Spinner';
import { selectPayments } from '../../redux/payments/selectors';
import { setPromocodeIsNull } from '../../redux/promocode/slice';
import { setPaymentStatusIsNull, setPaymentUrlIsNull } from '../../redux/payments/slice';
import { CheckPaymentFormData, CreateUrlPaymentFormData } from '../../redux/payments/types';
import { requestCreatePaymentUrl, requestCheckPayment } from '../../redux/payments/asyncActions';
import { requestMyActivePackages, requestMyInactivePackages } from '../../redux/my-packages/asyncActions';
import { QuestionCircleOutlined } from '@ant-design/icons';


const checkEmailRegex = (email: string) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( re.test(email) ) {
        return true;
    }
    else {
        return false;
    }
}


const ServicesPay = () => {
    // Router
    let { alias } = useParams();
    const navigate = useNavigate();
    const [promocode, setPromocode] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const intervalIdsRef = useRef<NodeJS.Timeout[]>([]);

    // Redux
    const dispatch = useAppDispatch();
    const { activePackage } = useSelector(selectPackages);

    useEffect(() => {
        if (alias) {
            dispatch(setPackageByAlias({ alias: alias }))
        }
    }, [dispatch, alias])

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Promocode
    const { status, message, promo_package } = useSelector(selectPromoCode);

    // Activate PromoCode
    const handlerActivatePromocode = (e: any) => {
        e.preventDefault();
        e.target.disabled = true;

        // Activate PromoCode
        if (promo_package) {
            messageApi.info('Вы уже активировали промокод', 2);
            e.target.disabled = false;
            return;
        }

        if (promocode.length === 0) {
            messageApi.error('Пожалуйста введите промокод', 2);
            e.target.disabled = false;
            return;
        }
 
        if (alias) {
            const formData: PromoCodeFormData = {
                promocode: promocode,
                service: alias,
            }
            dispatch(requestActivatePromoCode(formData));
        }
        e.target.disabled = false;
    }

    useEffect(() => {
        if (status !== Status.CALM && message.length > 0) {
            if (status === Status.SUCCESS) {
                messageApi.success(message, 2);
                return;
            }
            if (status === Status.ERROR) {
                messageApi.error(message, 2);
                return;
            }
        }
    }, [status, message, messageApi])

    // Payment Handler
    const WebApp = useWebApp();
    const { payment_url, statusPayment } = useSelector(selectPayments);

    const user: WebAppUser = WebApp.initDataUnsafe.user;

    const payment_handler = () => {
        if (activePackage) {
            if (email.length <= 0) {
                messageApi.error('Пожалуйста введите Email', 2);
                return;
            }
            if (!checkEmailRegex(email)) {
                messageApi.error('Пожалуйста введите Валидный Email', 2);
                return;
            }
            
            const formData: CreateUrlPaymentFormData = {
                alias: activePackage.alias,
                price: promo_package ? promo_package.price : activePackage.price,
                telegram_id: user.id,
                description: activePackage.description,
                verbose_name: activePackage.verbose_name,
                email: email,
            }
            dispatch(requestCreatePaymentUrl(formData));
        }
    }

    const [isOpenInvoice, setOpenInvoice] = useState<boolean>(false);

    useEffect(() => {
        if (payment_url) {
            // clearAllIntervals();
            WebApp.openInvoice(payment_url);
            setOpenInvoice(true);

            WebApp.onEvent('invoiceClosed', (object: any) => {
                if (object.status === 'cancelled') {
                    messageApi.info('Вы отменили оплату', 2);
                    // clearAllIntervals();
                    dispatch(setPaymentUrlIsNull());
                    dispatch(setPaymentStatusIsNull());
                }
            });
        }
        return () => {
            clearAllIntervals();

            // dispatch(setPromocodeIsNull());
            dispatch(setPaymentUrlIsNull());
            dispatch(setPaymentStatusIsNull());
            dispatch(requestMyActivePackages());
            dispatch(requestMyInactivePackages());
        }
    }, [payment_url, WebApp, dispatch])

    useEffect(() => {
        if (isOpenInvoice) {
            // Check Payment
            const formData: CheckPaymentFormData = {
                telegram_id: user.id,
            }

            if (intervalIdsRef.current.length === 0) {
                const intervalId = setInterval(() => {
                    dispatch(requestCheckPayment(formData));
                }, 5000);

                // Сохраняем идентификатор интервала в массиве
                intervalIdsRef.current.push(intervalId);
            }
        }
    }, [isOpenInvoice, dispatch])

    useEffect(() => {
        if (statusPayment) {
            clearAllIntervals();
            messageApi.success('Вы успешно оплатили услугу', 2);
        }
    }, [statusPayment])

    const clearAllIntervals = () => {
        intervalIdsRef.current.forEach(intervalId => clearInterval(intervalId));
        intervalIdsRef.current = [];  // Очищаем массив после удаления всех интервалов
    };


    const handleClickBackTelegram = () => {
        navigate(`/home/service/${alias}`);
    };

    return (
        <div className={style.services_pay}>
            <BackButton onClick={handleClickBackTelegram} />
            {contextHolder}
            {!activePackage
                ? (
                    <Spinner />
                )
                : (
                    <>
                        <div className={style.services_pay__price}>
                            <h2>К оплате: {promo_package ? promo_package.price : activePackage?.price}</h2>
                        </div>
                        <div className={style.services_pay__promocode}>
                            <p>Есть промокод?</p>
                            <form>
                                <div className={style.service_pay__promocode__block}>
                                    <input
                                        type="text"
                                        value={promocode}
                                        onChange={(e) => setPromocode(e.target.value)} />
                                    <button onClick={handlerActivatePromocode}>Применить</button>
                                </div>
                            </form>
                            <div className={style.email_title}>
                                <p>Ваш Email</p>
                                <QuestionCircleOutlined
                                    onClick={() => messageApi.info('Ваш Email нужен, для того, чтобы отправить чек вам на почту', 2)}
                                />
                            </div>
                            <form>
                                <div className={style.service_pay__email_block}>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} />
                                </div>
                            </form>
                        </div>
                        <MainButton onClick={payment_handler} color='#8DE719' text='Оплатить' />
                    </>
                )
            }
        </div>
    )
}

export default ServicesPay;
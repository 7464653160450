import { createAsyncThunk } from '@reduxjs/toolkit';

import { CreateUrlPaymentFormData, CheckPaymentFormData } from './types';
import PaymentsService from '../../services/PaymentsService';


export const requestCreatePaymentUrl = createAsyncThunk('/getting_invoice_link', async (formData: CreateUrlPaymentFormData) => {
    const response = await PaymentsService.createUrlPayment(formData);
    return response;
})

export const requestCheckPayment = createAsyncThunk('/check_payment', async (formData: CheckPaymentFormData) => {
    const response = await PaymentsService.checkPayment(formData);
    return response;
})
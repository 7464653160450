import { createSlice } from '@reduxjs/toolkit';

import { PaymentsSliceState, Status } from './types';
import { requestCreatePaymentUrl, requestCheckPayment } from './asyncActions';


const initialState: PaymentsSliceState = {
    message: '',
    status: Status.CALM as Status,
    payment_url: null,
    statusPayment: null,
}

const paymentSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        setPaymentUrlIsNull(state) {
            state.payment_url = null;
        },
        setPaymentStatusIsNull(state) {
            state.statusPayment = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(requestCreatePaymentUrl.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestCreatePaymentUrl.fulfilled, (state, action) => {
            state.status = Status.SUCCESS;
            if (action.payload) {
                state.payment_url = action.payload.url;
            }
        })
        builder.addCase(requestCreatePaymentUrl.rejected, (state) => {
            state.status = Status.ERROR;
        })
        // Check Payment
        builder.addCase(requestCheckPayment.pending, (state) => {})
        builder.addCase(requestCheckPayment.fulfilled, (state, action) => {
            if (action.payload) {
                state.statusPayment = action.payload.value;
            }
        })
        builder.addCase(requestCheckPayment.rejected, (state) => {
            state.statusPayment = false;
        })
    }
})

export const { setPaymentUrlIsNull, setPaymentStatusIsNull } = paymentSlice.actions;

export default paymentSlice.reducer;